<script setup lang="ts">
// Composables
const { $posthog } = useNuxtApp();

// Props
const modal = defineModel<boolean>('modelValue', { required: true });

// Methods
const closeModal = () => {
  $posthog.capture('generate_report_modal_click_on_close');
  modal.value = false;
};

const shareNeeds = () => {
  $posthog.capture('generate_report_modal_click_on_share_needs');
};
</script>
<template>
  <nrjx-modal v-model="modal" width="600" :title="$t('overview.generate_a_report')">
    <div class="flex flex-row gap-4 p-2">
      <nuxt-img src="/images/overview/report.png" alt="No report" class="w-[170px]" />
      <div>
        <h2 class="font-semibold text-gray-900">{{ $t('overview.generate_a_report_modal_title') }}</h2>
        <p class="text-gray-500 text-sm mt-2">
          {{ $t('overview.generate_a_report_modal_description') }}
        </p>
      </div>
    </div>

    <template #footer>
      <ui-button color="secondary" @click="closeModal()">Retour a la vue d'ensemble</ui-button>
      <a href="mailto:contact@nrjx.tech">
        <ui-button color="primary" @click="shareNeeds()">Partager mes besoins</ui-button>
      </a>
    </template>
  </nrjx-modal>
</template>

<script setup lang="ts">
// Composables
const { $crisp } = useNuxtApp();

// Methods
const openChat = () => {
  $crisp.chat.open();
  $crisp.chat.show();
};

$crisp.chat.onChatClosed(() => {
  $crisp.chat.hide();
});
</script>
<template>
  <div class="border border-gray-200 bg-gray-100 rounded p-4 flex flex-row gap-4">
    <div class="w-[160px] flex items-center justify-center">
      <nuxt-img src="/images/overview/contact-us.png" alt="Contact us" class="w-[160px]" />
    </div>
    <div class="flex flex-col items-start justify-center">
      <h2 class="font-semibold text-gray-900">{{ $t('overview.contact_us_title') }}</h2>
      <i18n-t keypath="overview.contact_us_description" tag="p" class="mt-2 text-gray-600 text-sm">
        <template #chat>
          <span class="text-primary-500 hover:text-primary-700 cursor-pointer underline lowercase" @click="openChat">{{
            $t('overview.chat')
          }}</span>
        </template>
        <template #phone>
          <a href="tel:+33743394043" class="text-primary-500 cursor-pointer underline hover:text-primary-700">07 43 39 40 43</a>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

import type { ApiConsumptionResponse } from '~/core/types/api';
import type { ObjectiveMilestone } from '../../types';
import { Objective_Types_Enum } from '../../types';

export const useOverviewObjectives = () => {
  // Composables
  const dayjs = useDayjs();

  // Repositories
  const { getConsumption } = coreConsumptionRepository();
  const { insertObjective, updateObjective } = useOverviewObjectiveRepository();

  // Methods
  // === Create objective ===
  const createObjective = async (form: {
    name: string;
    meterId: number;
    referenceYear: number;
    reductionObjectiveInPourcent: number;
    areaId: number;
  }) => {
    // Get consumption for the reference year
    const referenceYearConsumption = await getReferenceYearConsumption({
      meterId: form.meterId,
      areaId: form.areaId,
      referenceYear: form.referenceYear,
    });
    if (!referenceYearConsumption) throw new Error('Consumption not found');

    // Calculate the objective
    const objective = calculateObjective({
      name: form.name,
      meterId: form.meterId,
      consumptions: referenceYearConsumption.items,
      referenceYear: form.referenceYear,
      reductionObjectiveInPourcent: form.reductionObjectiveInPourcent,
    });

    try {
      await insertObjective(objective);
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : String(error));
    }
  };

  // === Update objective ===
  const manageObjective = async (form: {
    id: number;
    name: string;
    meterId: number;
    referenceYear: number;
    reductionObjectiveInPourcent: number;
    areaId: number;
  }) => {
    const meterType = await meterStore().getMeter(form.meterId)?.meter_type.energy_type;
    if (!meterType) throw new Error('Meter type not found');

    // Get consumption for the reference year
    const referenceYearConsumption = await getConsumption({
      area_id: form.areaId,
      start: `${form.referenceYear}-01-01`,
      end: `${form.referenceYear}-12-31`,
      energy_type: meterType,
      aggregation_level: 'month',
    });
    if (!referenceYearConsumption) throw new Error('Consumption not found');

    // Calculate the objective
    const objective = calculateObjective({
      name: form.name,
      meterId: form.meterId,
      consumptions: referenceYearConsumption.items,
      referenceYear: form.referenceYear,
      reductionObjectiveInPourcent: form.reductionObjectiveInPourcent,
    });

    try {
      await updateObjective(form.id, objective);
    } catch (error) {
      throw new Error(error instanceof Error ? error.message : String(error));
    }
  };

  // === Calculate objective ===
  const calculateObjective = (options: {
    name: string;
    meterId: number;
    consumptions: ApiConsumptionResponse['items'];
    reductionObjectiveInPourcent: number;
    referenceYear: number;
  }) => {
    const objectiveMilestones: Omit<ObjectiveMilestone, 'id' | 'objective_id'>[] = [];

    options.consumptions.map((consumption, index) => {
      const previousTarget = index === 0 ? 0 : (objectiveMilestones[index - 1]?.target ?? 0);
      const reductionFactor = 1 - options.reductionObjectiveInPourcent / 100;
      const currentConsumption = consumption.consumption.quantity * reductionFactor;
      const target = Math.round(previousTarget + currentConsumption);

      objectiveMilestones.push({
        timestamp: dayjs().startOf('year').add(index, 'month').toDate(),
        target,
      });
    });

    return {
      name: options.name,
      meter_id: options.meterId,
      target: objectiveMilestones.at(-1)?.target ?? 0,
      type: Objective_Types_Enum.Cumulated_consumption,
      start: dayjs().startOf('year').toDate(),
      end: dayjs().endOf('year').toDate(),
      reduction_objective_in_pourcent: options.reductionObjectiveInPourcent,
      reference_year: options.referenceYear,
      objective_milestones: {
        data: objectiveMilestones,
      },
    };
  };

  const cumulateConsumptions = (consumptions: ApiConsumptionResponse['items']) => {
    let cumulatedConsumption = 0;
    let cumulatedCost = 0;
    return consumptions.map((item) => {
      cumulatedConsumption += item.consumption.quantity;
      cumulatedCost += Number(item.consumption.cost);
      return { ...item, consumption: { cost: cumulatedCost, quantity: cumulatedConsumption } };
    });
  };

  // === Get reference year consumption ===
  const getReferenceYearConsumption = async (options: { meterId: number; areaId: number; referenceYear: number }) => {
    const meterType = await meterStore().getMeter(options.meterId)?.meter_type.energy_type;
    if (!meterType) throw new Error('Meter type not found');

    const referenceYearConsumption = await getConsumption({
      area_id: options.areaId,
      start: `${options.referenceYear}-01-01`,
      end: `${options.referenceYear}-12-31`,
      energy_type: meterType,
      aggregation_level: 'month',
    });

    if (!referenceYearConsumption) return null;
    return { ...referenceYearConsumption, items: cumulateConsumptions(referenceYearConsumption.items) };
  };

  // === Get the current year consumption ===
  const getCurrentYearConsumption = async (options: { meterId: number; areaId: number }) => {
    const meterType = await meterStore().getMeter(options.meterId)?.meter_type.energy_type;
    if (!meterType) throw new Error('Meter type not found');

    const currentYearConsumption = await getConsumption({
      area_id: options.areaId,
      start: `${dayjs().year()}-01-01`,
      end: `${dayjs().format('YYYY-MM-DD')}`,
      energy_type: meterType,
      aggregation_level: 'month',
    });

    if (!currentYearConsumption) return null;
    return { ...currentYearConsumption, items: cumulateConsumptions(currentYearConsumption.items) };
  };

  return {
    createObjective,
    manageObjective,
    calculateObjective,
    getReferenceYearConsumption,
    getCurrentYearConsumption,
  };
};

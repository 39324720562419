import type { ChartLineOptions } from '~/components/nrjx/chart/line.vue';
import type { Objective } from '../../types';
import type { TooltipData } from '~/components/nrjx/chart/tooltip/index.vue';
import { theme } from '#tailwind-config';

export const ObjectiveOptionChart = () => {
  // Composables
  const dayjs = useDayjs();
  const { formatNumberToIsoNumber } = useNumbers();
  const { t } = useI18n();

  // Methods
  const options = (options: {
    objective: Objective;
    referenceYearConsumption: ApiConsumptionResponse | null;
    currentYearConsumption: ApiConsumptionResponse | null;
  }): ChartLineOptions => {
    return {
      x: {
        ticks: {
          callback: (value: number) => {
            return dayjs(options.objective.objective_milestones[value]?.timestamp).format('MMMM / YYYY');
          },
        },
      },
      tooltip: (context) => {
        const objectiveIndex = context[0]?.index;
        if (typeof objectiveIndex !== 'number') return null;

        const tooltipData: TooltipData = {
          body: [
            {
              color: theme.colors.gray[500],
              title: t('overview.objective'),
              value: `${formatNumberToIsoNumber(Number(options.objective.objective_milestones[objectiveIndex]?.target))} kWh`,
            },
            {
              color: theme.colors.primary[500],
              title: dayjs(options.currentYearConsumption?.start).format('YYYY'),
              value: `${formatNumberToIsoNumber(options.currentYearConsumption?.items[objectiveIndex]?.consumption.quantity ?? 0)} kWh`,
            },
          ],
          footer: dayjs(options.objective.objective_milestones[objectiveIndex]?.timestamp).format('MMMM / YYYY'),
        };

        if (options.referenceYearConsumption && tooltipData.body) {
          tooltipData.body.push({
            color: theme.colors.green[500],
            title: options.objective.reference_year?.toString() ?? '',
            value: `${formatNumberToIsoNumber(options.referenceYearConsumption?.items[objectiveIndex]?.consumption.quantity ?? 0)} kWh`,
          });
        }

        return tooltipData;
      },
    };
  };

  return {
    options,
  };
};

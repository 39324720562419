import type { EnergyTypes, AggregationLevel } from '~/types/new/energy';

export const coreConsumptionRepository = () => {
  // Composables
  const { client } = useApiV2();

  const getConsumption = async (options: {
    area_id: number;
    start: string;
    end: string;
    energy_type: EnergyTypes;
    aggregation_level: AggregationLevel;
  }) => {
    const { data } = await client.GET('/v2/areas/{area_id}/explore/consumptions', {
      params: {
        query: {
          energy_type: options.energy_type,
          start: options.start,
          end: options.end,
          aggregation_level: options.aggregation_level,
        },
        path: { area_id: options.area_id },
      },
    });
    return data?.data;
  };

  return {
    getConsumption,
  };
};

<script lang="ts" setup>
import type { ChartLineData } from '~/components/nrjx/chart/line.vue';
import type { Objective } from '../../types';
import { theme } from '#tailwind-config';

// Composables
const dayjs = useDayjs();
const { options } = ObjectiveOptionChart();
const { getReferenceYearConsumption, getCurrentYearConsumption } = useOverviewObjectives();

// Props
const props = defineProps<{
  objective: Objective;
}>();

// Data
const loading = ref(false);
const referenceYearConsumptionData = ref<ApiConsumptionResponse | null>(null);
const currentYearConsumptionData = ref<ApiConsumptionResponse | null>(null);
const timestamps = ref<Date[]>([]);
const datasets = ref<ChartLineData['datasets']>([]);

// Computed
const chartOptions = computed(() => {
  return options({
    objective: props.objective,
    referenceYearConsumption: referenceYearConsumptionData.value,
    currentYearConsumption: currentYearConsumptionData.value,
  });
});

// Methods
const loadData = async () => {
  const areaId = areaStore().areas.find((area) => area.meters.find((meter) => meter.id === props.objective.meter_id))?.id;
  if (!areaId || !props.objective.meter_id) {
    return { referenceYearConsumption: null, currentYearConsumption: null };
  }

  const promises = [
    getCurrentYearConsumption({
      meterId: props.objective.meter_id,
      areaId: areaId,
    }),
  ];

  if (props.objective.reference_year) {
    promises.push(
      getReferenceYearConsumption({
        meterId: props.objective.meter_id,
        areaId: areaId,
        referenceYear: props.objective.reference_year,
      }),
    );
  }

  const response = await Promise.all(promises);

  return {
    currentYearConsumption: response?.at(0) ?? null,
    referenceYearConsumption: props.objective.reference_year ? (response?.at(1) ?? null) : null,
  };
};

const setData = async () => {
  loading.value = true;
  const { referenceYearConsumption, currentYearConsumption } = await loadData();
  if (!currentYearConsumption) return;

  referenceYearConsumptionData.value = referenceYearConsumption;
  currentYearConsumptionData.value = currentYearConsumption;

  timestamps.value = props.objective.objective_milestones.map((item) => dayjs(item.timestamp).toDate());

  datasets.value = [
    {
      label: 'Current year',
      data: currentYearConsumption.items.map((item) => item.consumption.quantity),
      color: theme.colors.primary[500],
    },
    {
      label: 'Objective',
      data: props.objective.objective_milestones.map((item) => item.target),
      color: theme.colors.green[900],
      dashed: true,
    },
  ];

  if (referenceYearConsumption) {
    datasets.value.unshift({
      label: 'Reference year',
      data: referenceYearConsumption.items.map((item) => item.consumption.quantity),
      color: theme.colors.green[400],
    });
  }

  loading.value = false;
};

// Lifecycle
watch(
  () => props.objective,
  () => {
    setData();
  },
  {
    deep: true,
    immediate: true,
  },
);
</script>
<template>
  <nrjx-chart-line
    title="kWh"
    :options="chartOptions"
    :loading="loading"
    :disabled-zoom="true"
    :data="{
      labels: timestamps,
      datasets: datasets,
    }"
  />
</template>

<script lang="ts" setup>
import type { Incident } from '../../types';

// Composables
const dayjs = useDayjs();
const { getIncidents } = overviewWeeklyRepository();

const props = defineProps<{
  week: {
    start: Date;
    end: Date;
  };
}>();

// Data
const loading = ref(true);
const incidents = ref<Incident[]>([]);

// Computed
const incidentCreated = computed(() =>
  incidents.value.filter((incident) => dayjs(incident._created_at).isBetween(dayjs(props.week.start), dayjs(props.week.end))),
);
const incidentOpen = computed(() => incidentCreated.value.filter((incident) => incident.status === 'alert'));

// Methods
const loadKpi = async () => {
  loading.value = true;
  incidents.value = await getIncidents(props.week.start, props.week.end);
  loading.value = false;
};

// Lifecycle
onMounted(() => {
  loadKpi();
});
</script>
<template>
  <div class="border border-gray-200 rounded p-3">
    <div class="flex items-center justify-start gap-3">
      <div class="h-[32px] w-[32px] bg-green-100 rounded flex items-center justify-center">
        <ui-icon name="TriangleAlert" class="text-primary-500 w-4 h-5" />
      </div>
      <ui-skeleton-loader v-if="loading" class="w-[250px] h-[28px] rounded-full" />
      <p v-else-if="incidentCreated.length > 0 && incidentOpen.length > 0" class="text-lg text-gray-600 font-semibold">
        {{ $t('overview.incident_created_and_incidents_open', { created: incidentCreated.length, open: incidentOpen.length }) }}
      </p>
      <p v-else class="text-lg text-gray-600 font-semibold">
        {{ $t('overview_no_incidents_this_week') }}
      </p>
    </div>
    <nuxt-link :to="{ name: 'incident-index' }">
      <ui-button color="secondary" class="mt-4 w-full">{{ $t('overview.view_incidents') }}</ui-button>
    </nuxt-link>
  </div>
</template>

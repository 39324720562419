import { gql } from '@apollo/client/core';

export const OVERVIEW_CONSUMPTION_REFERENCE_QUERY_LIST = gql`
  query overviewConsumptionReference($sitesIds: [Int!]!) {
    consumption_reference(where: { site_id: { _in: $sitesIds } }) {
      id
      co2
      consumption
      cost
      deployment_date
      production
      site_id
    }
  }
`;

import { gql } from '@apollo/client/core';

// Get the objectives for a list of meters
export const OVERVIEW_OBJECTIVES_QUERY_LIST = gql`
  query overview_objectives($meterIds: [Int!]!, $start: timestamptz!) {
    objectives(where: { meter_id: { _in: $meterIds }, start: { _eq: $start } }) {
      id
      type
      target
      start
      name
      meter_id
      end
      reduction_objective_in_pourcent
      reference_year
      objective_milestones {
        timestamp
        target
        objective_id
        id
      }
    }
  }
`;

// Create an objective
export const OVERVIEW_OBJECTIVE_INSERT_MUTATION = gql`
  mutation overview_objective_insert($objective: objectives_insert_input!) {
    insert_objectives_one(object: $objective) {
      id
    }
  }
`;

// Update an objective
export const OVERVIEW_OBJECTIVE_UPDATE_MUTATION = gql`
  mutation overview_objective_update($id: Int!, $objective: objectives_insert_input!) {
    delete_objectives(where: { id: { _eq: $id } }) {
      affected_rows
    }
    insert_objectives_one(object: $objective) {
      id
    }
  }
`;

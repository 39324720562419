import type { ReferenceConsumptionWithTotalConsumption } from '../../types';

export interface NorthStar {
  isBetter: boolean;
  quantity: number;
  cost: number;
  co2: number;
}

export const useOverviewNorthStar = () => {
  // Stores
  const { selectedSite } = globalStore();
  const { referenceConsumption } = storeToRefs(overviewStore());

  // Stores
  const { getRootAreas } = areaStore();

  // Composables
  const dayjs = useDayjs();
  const { getCo2byKWhByMeterId } = useCO2();

  // Repositories
  const { getConsumptionFromReference, getProductionFromReference, getReferenceConsumption } = overviewNorthStarRepository();

  const getNorthStar = async () => {
    // Load data
    await getReferenceConsumption(selectedSite);
    await Promise.all(
      referenceConsumption.value.map(async (reference) => {
        await getConsumptionFromReference(reference.id);
      }),
    );
    await Promise.all(
      referenceConsumption.value.map(async (reference) => {
        await getProductionFromReference(reference.id);
      }),
    );

    return calculateNorthStar({ references: referenceConsumption.value });
  };

  const calculateNorthStar = async (options: { references: ReferenceConsumptionWithTotalConsumption[] }): Promise<NorthStar> => {
    const all_sites_savings = options.references
      .map((reference) => {
        const siteHasProduction = typeof reference.production === 'number' && reference.production > 0;

        if (siteHasProduction) {
          if (reference.consumptionSinceDeployment && reference.productionSinceDeployment) {
            // Data
            const area = getRootAreas.find((area) => area.site.id === reference.site_id);
            const meter = area?.meters.find((meter) => meter.meter_type.energy_type === 'Electricity');
            const co2 = meter ? getCo2byKWhByMeterId(meter.id) * reference.consumptionSinceDeployment.quantity : 0;

            // Calcul average epi
            const averageEpi = {
              quantity: reference.consumptionSinceDeployment.quantity / Number(reference.productionSinceDeployment!),
              cost: Number(reference.consumptionSinceDeployment.cost) / Number(reference.productionSinceDeployment!),
              co2: co2 / Number(reference.productionSinceDeployment!),
            };

            // Calcul reference epi
            const referenceEpi = {
              quantity: reference.consumption / reference.production!,
              cost: reference.cost / reference.production!,
              co2: reference.co2 / reference.production!,
            };

            // Compute savings
            return {
              totalConsumption: reference.consumptionSinceDeployment.quantity,
              quantity: (referenceEpi.quantity - averageEpi.quantity) * Number(reference.productionSinceDeployment!),
              cost: (referenceEpi.cost - averageEpi.cost) * Number(reference.productionSinceDeployment!),
              co2: (referenceEpi.co2 - averageEpi.co2) * Number(reference.productionSinceDeployment!),
            };
          }
        } else {
          if (reference.consumptionSinceDeployment) {
            const daysBetweenDeploymentAndNow = dayjs().diff(dayjs(reference.deployment_date), 'days');
            const area = getRootAreas.find((area) => area.site.id === reference.site_id);
            const meter = area?.meters.find((meter) => meter.meter_type.energy_type === 'Electricity');
            const co2 = meter ? getCo2byKWhByMeterId(meter.id) * reference.consumptionSinceDeployment.quantity : 0;

            return {
              totalConsumption: reference.consumptionSinceDeployment.quantity,
              quantity: reference.consumption * (daysBetweenDeploymentAndNow / 365) - reference.consumptionSinceDeployment.quantity,
              cost: reference.cost * (daysBetweenDeploymentAndNow / 365) - Number(reference.consumptionSinceDeployment.cost),
              co2: reference.co2 * (daysBetweenDeploymentAndNow / 365) - co2,
            };
          }
        }

        // If not computable, return 0
        return {
          totalConsumption: 0,
          quantity: 0,
          cost: 0,
          co2: 0,
        };
      })
      .reduce(
        (acc, curr) => {
          return {
            totalConsumption: acc.totalConsumption + curr.totalConsumption,
            quantity: acc.quantity + curr.quantity,
            cost: acc.cost + curr.cost,
            co2: acc.co2 + curr.co2,
          };
        },
        {
          totalConsumption: 0,
          quantity: 0,
          cost: 0,
          co2: 0,
        },
      );

    const isBetterThreshold = 0.02;
    return {
      isBetter: all_sites_savings.quantity > all_sites_savings.totalConsumption * isBetterThreshold,
      quantity: all_sites_savings.quantity,
      cost: all_sites_savings.cost,
      co2: all_sites_savings.co2,
    };
  };

  return {
    getNorthStar,
  };
};

import type { EnergyTypes } from '~/types/new/energy';

export const coreProductionRepository = () => {
  // Composables
  const { client } = useApiV2();

  const getProductionEPI = async (options: { area_id: number; start: string; end: string; energy_type: EnergyTypes }) => {
    const response = await client.GET('/v2/areas/{area_id}/productions/epi/', {
      params: {
        query: {
          energy_type: options.energy_type,
          start: options.start,
          end: options.end,
        },
        path: { area_id: options.area_id },
      },
    });

    return {
      data: response.data?.data,
      error: response.data?.error,
    };
  };

  return {
    getProductionEPI,
  };
};

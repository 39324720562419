<script lang="ts" setup>
import type { Objective } from '../../types';
import ManageObjective from '../modal/manageObjective.vue';
import type { Area } from '~/types/area';
import ObjectiveChart from '../chart/objective.vue';
import ExportModal from '../modal/export.vue';

// Composables
const dayjs = useDayjs();
const { objectives } = storeToRefs(overviewStore());
const { $posthog } = useNuxtApp();

// Store
const { areas } = storeToRefs(areaStore());

// Repositories
const { getObjectives } = useOverviewObjectiveRepository();

// Data
const year = ref(dayjs().startOf('year').toDate());
const manageObjectiveModal = ref(false);
const selectedObjective = ref<Objective['id'] | null>(null);
const meters = ref<Area['meters']>([]);
const rootAreas = ref<Area[]>([]);
const manageExportModal = ref(false);

// Computed
const objective = computed(() => objectives.value.find((objective) => objective.id === selectedObjective.value));

// Methods
const loadObjectives = async () => {
  await getObjectives(
    meters.value.map((meter) => meter.id),
    year.value,
  );
  selectedObjective.value = objectives.value[0]?.id ?? null;
};

const previousYear = () => {
  year.value = dayjs(year.value).subtract(1, 'year').toDate();
  loadObjectives();
};

const nextYear = () => {
  year.value = dayjs(year.value).add(1, 'year').toDate();
  loadObjectives();
};

const openExportModal = () => {
  $posthog.capture('generate_report_click_button_objectives');
  manageExportModal.value = true;
};

// Lifecycle
onMounted(async () => {
  const allRootAreas = areas.value.filter((area) => area.parent_id === null);
  if (allRootAreas.length === 1 && allRootAreas[0]) {
    rootAreas.value = allRootAreas;
  }
  if (allRootAreas.length > 0) {
    meters.value = allRootAreas.map((area) => area.meters).flat();
    await loadObjectives();
  }
});
</script>
<template>
  <ui-card :title="$t('overview.objectives')">
    <template #header-description>
      <ui-icon name="ChevronLeft" class="cursor-pointer text-gray-500 w-4 h-4" @click="previousYear" />
      <p class="text-sm text-gray-500 select-none">{{ dayjs(year).format('YYYY') }}</p>
      <ui-icon name="ChevronRight" class="cursor-pointer text-gray-500 w-4 h-4" @click="nextYear" />
    </template>
    <template #header>
      <ui-button @click="openExportModal">{{ $t('overview.generate_a_report') }}</ui-button>
    </template>

    <div v-if="objectives.length > 0" class="flex items-center mb-4 justify-between">
      <!-- Objectives list -->
      <div class="flex flex-row gap-2">
        <div
          v-for="objectiveItem in objectives"
          :key="objectiveItem.id"
          class="flex h-[28px] items-center justify-start rounded-md text-sm px-2 select-none"
          :class="
            selectedObjective === objectiveItem.id ? 'bg-primary-50 text-primary-500' : 'text-gray-500 cursor-pointer hover:bg-gray-50'
          "
          @click="selectedObjective = objectiveItem.id"
        >
          {{ objectiveItem.name }}
        </div>
      </div>

      <!-- Add objective -->
      <ui-button color="tertiary" left-icon="Pen" @click="manageObjectiveModal = true">{{ $t('overview.edit_objectives') }}</ui-button>
    </div>

    <!-- Objective chart -->
    <objective-chart v-if="objective" :objective="objective" />
    <div v-if="objectives.length === 0" class="flex flex-col items-center justify-center h-[50vh] bg-gray-100 rounded-lg">
      <nuxt-img src="/images/dashboard/no-objective.png" alt="No objective" class="w-[170px]" />
      <p class="mt-4 text-gray-900 text-center">{{ $t('overview.no_objectives_title') }}</p>
      <p class="text-gray-600 text-sm mt-1 text-center">{{ $t('overview.no_objectives_description') }}</p>
      <ui-button left-icon="Plus" color="secondary" class="mt-2" @click="manageObjectiveModal = true">{{
        $t('overview.create_objective')
      }}</ui-button>
    </div>

    <!-- Modal -->
    <manage-objective
      v-if="rootAreas.length === 1 && rootAreas[0]"
      v-model="manageObjectiveModal"
      :area-id="rootAreas[0].id"
      :objectives="objectives"
      :meters="meters"
      @created="loadObjectives"
      @updated="loadObjectives"
    />
    <export-modal v-model="manageExportModal" />
  </ui-card>
</template>

export const overviewWeeklyService = () => {
  // Composables
  const { getConsumption } = coreConsumptionRepository();
  const { getProductionEPI } = coreProductionRepository();
  const { getRootAreas } = areaStore();
  const { getCo2byKWhByMeterId } = useCO2();
  const dayjs = useDayjs();

  // Data
  const areas = getRootAreas.map((area) => {
    return {
      id: area.id,
      meters: area.meters.map((meter) => meter),
    };
  });

  // =============================================================================================
  // Consumption
  // =============================================================================================
  const getConsumptionKpi = async (week: { start: Date; end: Date }) => {
    if (!areas || areas.length === 0) return;

    const consumptions = {
      currentWeekConsumption: {
        quantity: 0,
        cost: 0,
        co2: 0,
      },
      lastWeekConsumption: {
        quantity: 0,
        cost: 0,
        co2: 0,
      },
      error: false,
    };

    // Load data

    await Promise.all(
      areas?.map(async (area) => {
        await Promise.all(
          area.meters.map(async (meter) => {
            try {
              const co2byKWh = await getCo2byKWhByMeterId(meter.id);
              const currentWeekConsumption = await getConsumption({
                start: dayjs(week.start).format('YYYY-MM-DD'),
                end: dayjs(week.end).format('YYYY-MM-DD'),
                area_id: area.id,
                energy_type: meter.meter_type.energy_type,
                aggregation_level: 'day',
              });
              const lastWeekConsumption = await getConsumption({
                start: dayjs(week.start).subtract(1, 'week').format('YYYY-MM-DD'),
                end: dayjs(week.end).subtract(1, 'week').format('YYYY-MM-DD'),
                area_id: area.id,
                energy_type: meter.meter_type.energy_type,
                aggregation_level: 'day',
              });

              if (currentWeekConsumption?.total_consumption) {
                consumptions.currentWeekConsumption.quantity += currentWeekConsumption?.total_consumption.quantity;
                consumptions.currentWeekConsumption.cost += Number(currentWeekConsumption?.total_consumption.cost);
                consumptions.currentWeekConsumption.co2 += currentWeekConsumption?.total_consumption.quantity * co2byKWh;
              }
              if (lastWeekConsumption?.total_consumption) {
                consumptions.lastWeekConsumption.quantity += lastWeekConsumption?.total_consumption.quantity;
                consumptions.lastWeekConsumption.cost += Number(lastWeekConsumption?.total_consumption.cost);
                consumptions.lastWeekConsumption.co2 += lastWeekConsumption?.total_consumption.quantity * co2byKWh;
              }
            } catch (error) {
              consumptions.error = true;
              throw new Error('Error fetching consumption data: ' + error);
            }
          }),
        );
      }),
    );

    return calculateKpi(consumptions.currentWeekConsumption, consumptions.lastWeekConsumption, consumptions.error);
  };

  // =============================================================================================
  // Production
  // =============================================================================================
  const getProductionKpi = async (week: { start: Date; end: Date }) => {
    if (!areas || areas.length === 0) return;

    const productions = {
      currentWeekProduction: {
        quantity: 0,
        cost: 0,
        co2: 0,
      },
      lastWeekProduction: {
        quantity: 0,
        cost: 0,
        co2: 0,
      },
      error: false,
    };

    // Load data
    await Promise.all(
      areas?.map(async (area) => {
        await Promise.all(
          area.meters.map(async (meter) => {
            const co2byKWh = await getCo2byKWhByMeterId(meter.id);
            const currentWeekProduction = await getProductionEPI({
              start: dayjs(week.start).format('YYYY-MM-DD'),
              end: dayjs(week.end).format('YYYY-MM-DD'),
              area_id: area.id,
              energy_type: meter.meter_type.energy_type,
            });
            const lastWeekProduction = await getProductionEPI({
              start: dayjs(week.start).subtract(1, 'week').format('YYYY-MM-DD'),
              end: dayjs(week.end).subtract(1, 'week').format('YYYY-MM-DD'),
              area_id: area.id,
              energy_type: meter.meter_type.energy_type,
            });

            if (currentWeekProduction?.error || lastWeekProduction?.error) productions.error = true;
            if (currentWeekProduction?.data?.avg_consumption_per_unit) {
              productions.currentWeekProduction.quantity += currentWeekProduction.data.avg_consumption_per_unit;
              productions.currentWeekProduction.cost += Number(currentWeekProduction.data.avg_cost_per_unit);
              productions.currentWeekProduction.co2 += currentWeekProduction.data.avg_consumption_per_unit * co2byKWh;
            }
            if (lastWeekProduction?.data?.avg_consumption_per_unit) {
              productions.lastWeekProduction.quantity += lastWeekProduction.data.avg_consumption_per_unit;
              productions.lastWeekProduction.cost += Number(lastWeekProduction.data.avg_cost_per_unit);
              productions.lastWeekProduction.co2 += lastWeekProduction.data.avg_consumption_per_unit * co2byKWh;
            }
          }),
        );
      }),
    );

    return calculateKpi(productions.currentWeekProduction, productions.lastWeekProduction, productions.error);
  };

  // =============================================================================================
  // Calculate KPI
  // =============================================================================================
  const calculateKpi = (
    currentWeek: { quantity: number; cost: number; co2: number },
    lastWeek: { quantity: number; cost: number; co2: number },
    error: boolean,
  ) => {
    return {
      cost: currentWeek.cost,
      quantity: currentWeek.quantity,
      co2: currentWeek.co2,
      pourcent: ((currentWeek.quantity - lastWeek.quantity) / lastWeek.quantity) * 100,
      error,
    };
  };
  return {
    getConsumptionKpi,
    getProductionKpi,
  };
};

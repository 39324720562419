import {
  OVERVIEW_OBJECTIVE_INSERT_MUTATION,
  OVERVIEW_OBJECTIVE_UPDATE_MUTATION,
  OVERVIEW_OBJECTIVES_QUERY_LIST,
} from '../../graphql/objective';
import type { GraphResponseObjective, ObjectiveCreate } from '../../types';

export const useOverviewObjectiveRepository = () => {
  // Store
  const { setObjectives } = overviewStore();

  // Composables
  const { apolloClient } = useApollo();

  const getObjectives = async (meterIds: number[], start: Date) => {
    const { data } = await apolloClient.query<GraphResponseObjective>({
      query: OVERVIEW_OBJECTIVES_QUERY_LIST,
      variables: { meterIds, start },
    });
    await setObjectives(data.objectives);
  };

  const insertObjective = async (objective: ObjectiveCreate) => {
    await apolloClient.mutate({
      mutation: OVERVIEW_OBJECTIVE_INSERT_MUTATION,
      variables: { objective },
    });
  };

  const updateObjective = async (id: number, objective: ObjectiveCreate) => {
    await apolloClient.mutate({
      mutation: OVERVIEW_OBJECTIVE_UPDATE_MUTATION,
      variables: { id, objective },
    });
  };

  return {
    getObjectives,
    insertObjective,
    updateObjective,
  };
};

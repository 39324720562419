<script lang="ts" setup>
// Composables
const { consumptionSelectItems } = coreEnergy();
const { getConsumptionKpi } = overviewWeeklyService();
const { formatConsumption, formatNumberToIsoNumber, formatCo2 } = useNumbers();

// Props
const props = defineProps<{
  week: {
    start: Date;
    end: Date;
  };
}>();

// Data
const loading = ref(false);
const consumptionSelectKey = ref<'quantity' | 'cost' | 'co2'>('quantity');
const consumptionKpi = ref<{ quantity: number; cost: number; pourcent: number; co2: number }>();

// Computed
const isPositive = computed(() => consumptionKpi.value?.pourcent && consumptionKpi.value?.pourcent < 0);

// Methods
const formatKpi = computed(() => {
  if (!consumptionKpi.value) return '--';
  switch (consumptionSelectKey.value) {
    case 'quantity':
      return formatConsumption(consumptionKpi.value[consumptionSelectKey.value]);
    case 'cost':
      return `${formatNumberToIsoNumber(consumptionKpi.value[consumptionSelectKey.value])} €`;
    case 'co2':
      return formatCo2(consumptionKpi.value[consumptionSelectKey.value]);
  }
  return '';
});

const loadKpi = async () => {
  loading.value = true;
  consumptionKpi.value = await getConsumptionKpi(props.week);
  loading.value = false;
};

// Lifecycle
onMounted(async () => {
  await loadKpi();
});

watch(
  () => props.week,
  async () => {
    await loadKpi();
  },
);
</script>
<template>
  <div class="border border-gray-200 rounded p-3">
    <div class="w-full flex items-center justify-between">
      <!-- Data -->
      <div class="flex items-center justify-start mb-1">
        <ui-skeleton-loader v-if="loading" class="w-[150px] h-[28px] rounded-full" />

        <template v-else>
          <p class="text-lg font-semibold text-gray-600 mr-2">{{ formatKpi }}</p>
          <ui-badge v-if="consumptionKpi?.pourcent && consumptionKpi?.pourcent !== -100" :color="isPositive ? 'primary' : 'red'">
            {{ Math.sign(consumptionKpi?.pourcent) === 1 ? '+' : '-' }}
            {{ formatNumberToIsoNumber(Math.abs(consumptionKpi?.pourcent ?? 0)) }} %
          </ui-badge>
        </template>
      </div>

      <!-- Select -->
      <nrjx-input-select
        v-model="consumptionSelectKey"
        name="consumptionSelectKey"
        :items="consumptionSelectItems"
        item-key="value"
        item-label="label"
        :content="{
          direction: 'bottom',
          align: 'end',
          sideOffset: 8,
        }"
        class="!w-auto flex"
      >
        <template #trigger>
          <ui-icon name="ChevronDown" class="text-gray-500 w-5 h-5 mb-0.5" />
        </template>
      </nrjx-input-select>
    </div>
    <p class="text-sm text-gray-500">{{ $t('overview.consumption_of_the_week') }}</p>
  </div>
</template>

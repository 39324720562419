export const coreEnergy = () => {
  const { t } = useI18n();

  const consumptionSelectItems = ref<{ label: string; value: 'quantity' | 'cost' | 'co2' }[]>([
    { label: t('global.quantity'), value: 'quantity' },
    { label: t('global.cost'), value: 'cost' },
    { label: 'CO₂', value: 'co2' },
  ]);

  return {
    consumptionSelectItems,
  };
};

import { OVERVIEW_CONSUMPTION_REFERENCE_QUERY_LIST } from '../../graphql/referenceConsumption';
import type { GraphResponseReferenceConsumption } from '../../types';

export const overviewNorthStarRepository = () => {
  // Store
  const {
    getReferenceConsumptionById,
    setConsumptionForReferenceConsumption,
    setProductionForReferenceConsumption,
    setReferenceConsumption,
  } = overviewStore();
  const { getRootAreas } = areaStore();

  // Composables
  const { apolloClient } = useApollo();
  const { client } = useApiV2();
  const dayjs = useDayjs();

  const getConsumptionFromReference = async (referenceId: number) => {
    const reference = getReferenceConsumptionById(referenceId);
    const areaId = getRootAreas.find((area) => area.site_id === reference?.site_id)?.id;

    if (!reference || !areaId) return;

    const startDate = dayjs(reference.deployment_date).format('YYYY-MM-DD');
    const endDate = dayjs().format('YYYY-MM-DD');

    const { data } = await client.GET('/v2/areas/{area_id}/explore/consumptions', {
      params: {
        query: {
          energy_type: 'Electricity',
          start: startDate,
          end: endDate,
          aggregation_level: 'day',
        },
        path: {
          area_id: areaId,
        },
      },
    });

    if (data?.data) {
      setConsumptionForReferenceConsumption(data.data.total_consumption, referenceId);
    }
  };

  const getProductionFromReference = async (referenceId: number) => {
    const reference = getReferenceConsumptionById(referenceId);
    const areaId = getRootAreas.find((area) => area.site_id === reference?.site_id)?.id;

    if (!reference || !areaId) return;

    const startDate = dayjs(reference.deployment_date).format('YYYY-MM-DD');
    const endDate = dayjs().format('YYYY-MM-DD');

    const { data } = await client.GET('/v2/areas/{area_id}/explore/productions', {
      params: {
        query: {
          energy_type: 'Electricity',
          start: startDate,
          end: endDate,
          aggregation_level: 'day',
        },
        path: { area_id: areaId },
      },
    });

    if (data?.data) {
      setProductionForReferenceConsumption(data.data.total_quantity, referenceId);
    }
  };

  const getReferenceConsumption = async (sitesIds: number[]) => {
    const { data } = await apolloClient.query<GraphResponseReferenceConsumption>({
      query: OVERVIEW_CONSUMPTION_REFERENCE_QUERY_LIST,
      variables: { sitesIds: sitesIds },
    });
    await setReferenceConsumption(data.consumption_reference);
  };

  return {
    getConsumptionFromReference,
    getProductionFromReference,
    getReferenceConsumption,
  };
};

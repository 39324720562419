<script lang="ts" setup>
import WeeklyConsumptionKpi from '../kpi/weeklyConsumption.vue';
import WeeklyEpiKpi from '../kpi/weeklyEPI.vue';
import IncidentsKpi from '../kpi/incidents.vue';
import ExportModal from '../modal/export.vue';

// Composables
const dayjs = useDayjs();

// Data
const manageExportModal = ref(false);
const week = ref({
  start: dayjs().startOf('week').toDate(),
  end: dayjs().endOf('week').toDate(),
});
const currentWeek = ref({
  start: dayjs().startOf('week').toDate(),
  end: dayjs().endOf('week').toDate(),
});
const previousWeek = () => {
  week.value = {
    start: dayjs(week.value.start).subtract(1, 'week').toDate(),
    end: dayjs(week.value.end).subtract(1, 'week').toDate(),
  };
};

const nextWeek = () => {
  if (dayjs(week.value.start).isSame(currentWeek.value.start, 'day')) return;
  week.value = {
    start: dayjs(week.value.start).add(1, 'week').toDate(),
    end: dayjs(week.value.end).add(1, 'week').toDate(),
  };
};
</script>
<template>
  <ui-card :title="$t('overview.weekly_follow_up')">
    <template #header-description>
      <ui-icon name="ChevronLeft" class="cursor-pointer text-gray-500 w-4 h-4" @click="previousWeek" />
      <p class="text-sm text-gray-500 select-none">
        {{ $t('global.from_date') }}
        {{ dayjs(week.start).format('DD/MM/YYYY') }} {{ $t('global.to_date').toLocaleLowerCase() }}
        {{ dayjs(week.end).format('DD/MM/YYYY') }}
      </p>
      <ui-icon
        name="ChevronRight"
        class="w-4 h-4"
        :class="$dayjs(week.start).isSame(currentWeek.start, 'day') ? 'text-gray-200 cursor-not-allowed' : 'text-gray-500 cursor-pointer'"
        @click="nextWeek"
      />
    </template>

    <template #header>
      <ui-button @click="manageExportModal = true">{{ $t('overview.generate_a_report') }}</ui-button>
    </template>

    <div class="grid grid-cols-2 gap-4">
      <weekly-consumption-kpi class="col-span-1" :week="week" />
      <weekly-epi-kpi class="col-span-1" :week="week" />
      <incidents-kpi class="col-span-2" :week="week" />
    </div>

    <!-- Modal -->
    <export-modal v-model="manageExportModal" />
  </ui-card>
</template>

import type { Objective, ReferenceConsumption, ReferenceConsumptionWithTotalConsumption } from '../types';

interface State {
  referenceConsumption: ReferenceConsumptionWithTotalConsumption[];
  objectives: Objective[];
}

export const overviewStore = defineStore('overviewStore', {
  state: (): State => ({
    referenceConsumption: [],
    objectives: [],
  }),
  getters: {
    getReferenceConsumptionById:
      (state) =>
      (id: number): ReferenceConsumptionWithTotalConsumption | undefined => {
        return state.referenceConsumption.find((reference) => reference.id === id);
      },
  },
  actions: {
    // =============================================================================================
    // Reference consumption
    // =============================================================================================
    async setReferenceConsumption(referenceConsumption: ReferenceConsumption[]) {
      this.referenceConsumption = referenceConsumption.map((reference) => ({
        ...reference,
        consumptionSinceDeployment: undefined,
      }));
    },
    async setConsumptionForReferenceConsumption(
      consumption: ReferenceConsumptionWithTotalConsumption['consumptionSinceDeployment'],
      referenceId: number,
    ) {
      const reference = this.getReferenceConsumptionById(referenceId);
      if (!reference) return;
      reference.consumptionSinceDeployment = consumption;
    },
    async setProductionForReferenceConsumption(
      productionSinceDeployment: ReferenceConsumptionWithTotalConsumption['productionSinceDeployment'],
      referenceId: number,
    ) {
      const reference = this.getReferenceConsumptionById(referenceId);
      if (!reference) return;
      reference.productionSinceDeployment = productionSinceDeployment;
    },

    // =============================================================================================
    // Objectives
    // =============================================================================================
    async setObjectives(objectives: Objective[]) {
      this.objectives = objectives;
    },
  },
});

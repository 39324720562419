import { gql } from '@apollo/client/core';

// Get incidents from two dates
export const OVERVIEW_INCIDENTS_QUERY_LIST_FROM_TWO_DATES = gql`
  query overview_incidents($start: timestamptz, $end: timestamptz) {
    incidents(where: { _created_at: { _gte: $start, _lte: $end } }) {
      _created_at
      status
      type
      cost
    }
  }
`;

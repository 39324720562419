<script lang="ts" setup>
// Composables
const { consumptionSelectItems } = coreEnergy();
const { getProductionKpi } = overviewWeeklyService();
const { formatNumberToIsoNumber, formatCo2 } = useNumbers();

// Props
const props = defineProps<{
  week: {
    start: Date;
    end: Date;
  };
}>();

// Store
const { getAreas } = areaStore();

// Data
const loading = ref(false);
const epiSelectKey = ref<'quantity' | 'cost' | 'co2'>('quantity');
const epiKpi = ref<{ quantity: number; cost: number; pourcent: number; co2: number; error: boolean }>();

// Computed
const isPositive = computed(() => epiKpi.value?.pourcent && epiKpi.value?.pourcent < 0);

// Methods
const formatKpi = computed(() => {
  const area = getAreas.filter((area) => area.parent_id === null)[0];
  if (!epiKpi.value || !area) return '--';

  switch (epiSelectKey.value) {
    case 'quantity':
      return `${formatNumberToIsoNumber(epiKpi.value[epiSelectKey.value], 2)} kWh / ${area.production_unit?.symbol ?? '--'}`;
    case 'cost':
      return `${formatNumberToIsoNumber(epiKpi.value[epiSelectKey.value])} € / ${area.production_unit?.symbol ?? '--'}`;
    case 'co2':
      return `${formatCo2(epiKpi.value[epiSelectKey.value])} / ${area.production_unit?.symbol ?? '--'}`;
  }
  return '';
});

const loadKpi = async () => {
  loading.value = true;
  epiKpi.value = await getProductionKpi(props.week);
  loading.value = false;
};

// Lifecycle
onMounted(() => {
  loadKpi();
});

watch(
  () => props.week,
  async () => {
    await loadKpi();
  },
);
</script>
<template>
  <div class="border border-gray-200 rounded p-3">
    <div class="w-full flex items-center justify-between">
      <!-- Data -->
      <div class="flex items-center justify-start mb-1">
        <ui-skeleton-loader v-if="loading" class="w-[150px] h-[28px] rounded-full" />
        <ui-badge v-else-if="epiKpi?.error" color="gray" class="text-lg font-semibold text-gray-600 mr-2">
          <nuxt-link :to="{ name: 'config-areas-index' }" class="underline">
            {{ $t('overview.production_error') }}
          </nuxt-link>
        </ui-badge>
        <template v-else>
          <p class="text-lg font-semibold text-gray-600 mr-2">{{ formatKpi }}</p>
          <ui-badge v-if="epiKpi?.pourcent && epiKpi?.pourcent !== -100" :color="isPositive ? 'primary' : 'red'">
            {{ Math.sign(epiKpi?.pourcent) === 1 ? '+' : '-' }}
            {{ formatNumberToIsoNumber(Math.abs(epiKpi?.pourcent ?? 0)) }} %
          </ui-badge>
        </template>
      </div>

      <!-- Select -->
      <nrjx-input-select
        v-model="epiSelectKey"
        name="epiSelectKey"
        :items="consumptionSelectItems"
        item-key="value"
        item-label="label"
        :content="{
          direction: 'bottom',
          align: 'end',
          sideOffset: 8,
        }"
        class="!w-auto flex"
      >
        <template #trigger>
          <ui-icon name="ChevronDown" class="text-gray-500 w-5 h-5 mb-0.5" />
        </template>
      </nrjx-input-select>
    </div>
    <p class="text-sm text-gray-500">{{ $t('overview.epi_of_the factory') }}</p>
  </div>
</template>

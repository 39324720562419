import type { Overview_IncidentsQuery } from '~/types/graphql/graphql';
import { OVERVIEW_INCIDENTS_QUERY_LIST_FROM_TWO_DATES } from '../../graphql/incident';

export const overviewWeeklyRepository = () => {
  // Composables
  const { apolloClient } = useApollo();

  const getIncidents = async (start: Date, end: Date) => {
    const { data } = await apolloClient.query<Overview_IncidentsQuery>({
      query: OVERVIEW_INCIDENTS_QUERY_LIST_FROM_TWO_DATES,
      variables: { start, end },
    });
    return data.incidents;
  };

  return {
    getIncidents,
  };
};

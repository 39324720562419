<script lang="ts" setup>
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import type { Objective } from '../../types';
import type { Area } from '~/types/area';

// Composables
const { t } = useI18n();
const { handleSubmit, resetForm } = useForm({
  validationSchema: yup.object({
    meterId: yup.string().required(t('global.field_required')),
    name: yup.string().required(t('global.field_required')),
    reductionObjectiveInPourcent: yup
      .number()
      .required(t('global.field_required'))
      .moreThan(0, t('overview.reduction_objective_min_error'))
      .lessThan(100, t('overview.reduction_objective_max_error'))
      .typeError(t('global.field_required')),
    referenceYear: yup.number().required(t('global.field_required')),
  }),
});

// Services
const { createObjective, manageObjective } = useOverviewObjectives();

// Store
const { showToast } = toastStore();

// Props
const modal = defineModel<boolean>();
const emit = defineEmits<{
  (e: 'created' | 'updated'): void;
}>();
const props = defineProps<{
  areaId: number;
  objectives: Objective[];
  meters: Area['meters'];
}>();

// Data
const loading = ref(false);
const editMode = ref<'create' | 'edit'>('create');
const selectedObjectiveId = ref<Objective['id'] | undefined>(undefined);

// Computed
const listOfObjectives = computed(() => {
  return props.objectives?.map((objective) => ({
    id: objective.id,
    label: objective.name ?? '--',
  }));
});

const listOfMeters = computed(() => {
  return props.meters?.map((meter) => ({
    id: meter.id,
    label: t(`global.energy_type.${meter.meter_type.energy_type}`) ?? '--',
  }));
});

const listOfYears = computed(() => {
  return Array.from({ length: 8 }, (_, index) => 2024 - index).map((year) => ({
    id: year,
    label: year.toString(),
  }));
});

const currentObjective = computed(() => {
  return props.objectives.find((objective) => objective.id === selectedObjectiveId.value);
});

const title = computed(() => {
  return editMode.value === 'create' ? t('overview.add_objective') : t('overview.manage_objectives');
});

// Methods
const submit = handleSubmit(async (values) => {
  loading.value = true;
  const objective = {
    name: values.name,
    meterId: values.meterId,
    referenceYear: values.referenceYear,
    reductionObjectiveInPourcent: values.reductionObjectiveInPourcent,
    areaId: props.areaId,
  };

  // Create or update the objective
  try {
    if (editMode.value === 'create') {
      await createObjective(objective);
      emit('created');
    } else if (editMode.value === 'edit' && currentObjective.value?.id) {
      await manageObjective({
        id: currentObjective.value?.id,
        ...objective,
      });
      emit('updated');
    } else {
      throw new Error('Objective not found');
    }
  } catch (error) {
    showToast({
      type: 'error',
      title: t('global.error'),
      message:
        editMode.value === 'create' ? t('overview.error_creating_objective_message') : t('overview.error_updating_objective_message'),
    });
    throw error;
  } finally {
    loading.value = false;
    modal.value = false;
    showToast({
      type: 'success',
      title: t('global.success'),
      message: editMode.value === 'create' ? t('overview.objective_created_message') : t('overview.objective_updated_message'),
    });
  }
});

const setCreateMode = () => {
  editMode.value = 'create';
  selectedObjectiveId.value = undefined;
  resetForm({
    values: {
      name: undefined,
      meterId: undefined,
      reductionObjectiveInPourcent: undefined,
      referenceYear: undefined,
    },
  });
};

// Lifecycle
watch(modal, (value) => {
  if (value && props.objectives?.[0]) {
    selectedObjectiveId.value = props.objectives[0].id;
    if (currentObjective.value) {
      editMode.value = 'edit';
      resetForm({
        values: {
          objectiveId: currentObjective.value?.id,
          name: currentObjective.value?.name,
          meterId: currentObjective.value?.meter_id,
          reductionObjectiveInPourcent: currentObjective.value?.reduction_objective_in_pourcent,
          referenceYear: currentObjective.value?.reference_year,
        },
      });
    } else {
      editMode.value = 'create';
    }
  }
});

watch(selectedObjectiveId, () => {
  resetForm({
    values: {
      objectiveId: currentObjective.value?.id,
      name: currentObjective.value?.name,
      meterId: currentObjective.value?.meter_id,
      reductionObjectiveInPourcent: currentObjective.value?.reduction_objective_in_pourcent,
      referenceYear: currentObjective.value?.reference_year,
    },
  });
});
</script>
<template>
  <nrjx-modal v-model="modal" :title="title" width="600">
    <nrjx-input-select
      v-if="objectives.length > 0 && editMode === 'edit'"
      v-model="selectedObjectiveId"
      :label="$t('overview.objective')"
      name="objectiveId"
      item-key="id"
      item-label="label"
      :items="listOfObjectives"
      fit-to-select
    />
    <hr v-if="objectives.length > 0 && editMode === 'edit'" class="text-gray-200 my-4" />

    <!-- Form -->
    <div class="p-4 bg-gray-50 rounded">
      <ui-form-input-text autocomplete="off" :label="$t('overview.objective_name')" name="name" />
      <nrjx-input-select
        :label="$t('global.energy')"
        name="meterId"
        fit-to-select
        item-key="id"
        item-label="label"
        :items="listOfMeters"
        :hide-details="false"
      />
      <ui-form-input-text
        autocomplete="off"
        type="number"
        :label="$t('overview.reduction_objective')"
        right-text="%"
        name="reductionObjectiveInPourcent"
      />
      <nrjx-input-select
        :label="$t('overview.reference_year')"
        name="referenceYear"
        fit-to-select
        item-key="id"
        item-label="label"
        :items="listOfYears"
        :hide-details="false"
      />
    </div>

    <template #footer>
      <div class="flex flex-row w-full" :class="[editMode === 'create' ? 'justify-end' : 'justify-between']">
        <ui-button v-if="editMode === 'edit'" left-icon="Plus" color="tertiary" @click="setCreateMode">
          {{ $t('overview.add_objective') }}
        </ui-button>
        <div class="flex flex-row gap-4 justify-end">
          <ui-button :color="loading ? 'disabled' : 'secondary'" @click="modal = false">{{ $t('global.cancel') }}</ui-button>
          <ui-button color="primary" :loading="loading" @click="submit">{{ $t('overview.save_objective') }}</ui-button>
        </div>
      </div>
    </template>
  </nrjx-modal>
</template>

<script lang="ts" setup>
// Composables
const { t } = useI18n();
const { formatNumberToIsoNumber, formatConsumption, formatCo2 } = useNumbers();

// Services
const { getNorthStar } = useOverviewNorthStar();

// Data
const northStarKey = ref<'quantity' | 'cost' | 'co2'>('quantity');
const northStar = ref<NorthStar | null>(null);
const northStarItems = ref<{ label: string; value: 'quantity' | 'cost' | 'co2' }[]>([
  { label: t('global.quantity'), value: 'quantity' },
  { label: t('global.cost'), value: 'cost' },
  { label: 'CO₂', value: 'co2' },
]);

// Computed
const user = computed(() => userStore().currentUser);

const formatNorthStar = (key: typeof northStarKey.value) => {
  if (!northStar.value) return '';
  switch (key) {
    case 'quantity':
      return formatConsumption(northStar.value[key]);
    case 'cost':
      return `${formatNumberToIsoNumber(northStar.value[key])} €`;
    case 'co2':
      return formatCo2(northStar.value[key]);
  }
};

onMounted(async () => {
  const northStarData = await getNorthStar();
  northStar.value = northStarData;
});
</script>
<template>
  <div class="flex items-end p-2 pb-6 border-b border-gray-200">
    <h1 class="text-3xl font-semibold text-primary-500 mr-4">{{ $t('global.hello') }} {{ user?.first_name }} !</h1>
    <template v-if="northStar?.isBetter">
      <p class="font-semibold text-gray-700">{{ $t('overview.title_description') }}</p>
      <p class="font-semibold text-primary-500 mx-2">{{ formatNorthStar(northStarKey) }}</p>
      <div class="h-full flex items-center">
        <nrjx-input-select
          v-model="northStarKey"
          name="northStarKey"
          :items="northStarItems"
          item-key="value"
          item-label="label"
          :content="{
            direction: 'bottom',
            align: 'end',
            sideOffset: 8,
          }"
          class="!w-auto flex"
        >
          <template #trigger>
            <ui-icon name="ChevronDown" class="text-gray-500 w-5 h-5 mb-0.5" />
          </template>
        </nrjx-input-select>

        <!-- <hover-card-root :open-delay="300" :close-delay="300">
          <hover-card-trigger>
            <ui-icon name="Info" class="w-4 h-4 ml-4 text-gray-600" />
          </hover-card-trigger>
          <hover-card-portal>
            <hover-card-content side="right" :side-offset="8" align="start" class="bg-white p-4 rounded-md shadow w-[300px]">
              <p class="text-sm text-gray-500">???? text ???</p>
            </hover-card-content>
          </hover-card-portal>
        </hover-card-root> -->
      </div>
    </template>
  </div>
</template>

import type { components } from '@nrjx/api';
import type {
  Overview_IncidentsQuery,
  Overview_Objective_InsertMutationVariables,
  Overview_ObjectivesQuery,
  OverviewConsumptionReferenceQuery,
} from '~/types/graphql/graphql';

// =============================================================================================
// Reference consumption
// =============================================================================================
export type GraphResponseReferenceConsumption = OverviewConsumptionReferenceQuery;
export type ReferenceConsumption = GraphResponseReferenceConsumption['consumption_reference'][number];
export interface ReferenceConsumptionWithTotalConsumption extends ReferenceConsumption {
  consumptionSinceDeployment?: components['schemas']['ConsumptionPointList']['total_consumption'];
  productionSinceDeployment?:
    | components['schemas']['FabricationOrderEPIList']['total_quantity']
    | components['schemas']['ProductionEPIList']['total_quantity']
    | components['schemas']['AggregatedProductionEPIList']['total_quantity'];
}

// =============================================================================================
// Objectives
// =============================================================================================
export type GraphResponseObjective = Overview_ObjectivesQuery;
export type Objective = GraphResponseObjective['objectives'][number];
export type ObjectiveCreate = Overview_Objective_InsertMutationVariables['objective'];
export type ObjectiveMilestone = Overview_ObjectivesQuery['objectives'][number]['objective_milestones'][number];
export enum Objective_Types_Enum {
  Consumption = 'Consumption',
  Cumulated_consumption = 'Cumulated_consumption',
}

// =============================================================================================
// Incidents
// =============================================================================================
export type GraphResponseIncident = Overview_IncidentsQuery;
export type Incident = GraphResponseIncident['incidents'][number];
